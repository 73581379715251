.skewed-bg {
    background: #000;
    /* background: #014366; */
    padding: 60px 0 60px 0;
    color: white
        /* -webkit-transform: skew(0deg, -5deg); */
        /* transform: skew(0deg, -5deg); */
        /* margin-top: -100px; */
        /* z-index: -1; */
}

/* .skewed-bg .content {
    font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 600;
    -webkit-transform: skew(0deg, 5deg);
    transform: skew(0deg, 5deg);
    text-align: center;
} */