html,
body {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.firasans {
  font-family: "Fira Sans", sans-serif;
}

.mukta {
  font-family: "Mukta";
}

.raleway {
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
}