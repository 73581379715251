.coverview {
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    color: white;
    width: 100%;
    background-image: url('night_tent.jpg'), linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)) !important;
    background-size: cover !important;
    -webkit-background-size: cover cover !important;
    background-repeat: no-repeat;
}

.carousel-item {
    position: relative !important;
}

.black-overlay {
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.6)
}

.wrapper {
    min-height: calc(var(--vh, 1vh) * 100);
    z-index: 10;
    /* margin-top: 8em; */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* h1.ted::after {
    content: "x";
    font-size: 0.7em;
    vertical-align: super;
} */

.venue-details>span {
    font-family: sans-serif;
    font-weight: bold;
    font-size: 1.2em;
    margin-left: 5px;
}

.venue-details h1 {
    color: white;
    font-size: 3em;
}

h1.venue {
    color: white;
    font-size: 2em;
}


@media (max-width: 768px) {
    .layer {
        /* visibility: hidden; */
        opacity: 0.3;
        /* position: absolute;
      left: 400px; */
    }

    .wrapper {
        align-items: center;
    }

    .logoinjumbotron {
        width: 100%;
    }

    .venue-details h1 {
        font-size: 2em;
    }

    .venue {
        font-family: sans-serif;
        font-size: 1.5em !important;
        margin-left: 5px;
    }

    .venue-details {
        padding: 80px 10px 0;
    }

    .tedxicem {
        padding: 60px 10px 0;
    }
}

@media (min-width: 1200px) {
    /* .venue-details {
        padding: 130px 30px 0;
    }

    .tedxicem {
        padding: 70px 30px 0;
    } */
}