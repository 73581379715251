.navbar {
    scroll-behavior: smooth;
    transition: background-color 0.4s ease 0s;
}

/* #tedlogonav {
    visibility: hidden;
} */

.bg-nav {
    background-color: #0e0e0e;
}

.nav-link {
    font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 600;
    color: white !important;
    position: relative;
}

.navbar.active {
    background: #0e0e0e;
}

.underline:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 2;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: #014A73;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

.underline:hover:after {
    width: 100%;
    left: 0;
}

.navbar.active .nav-link {
    color: #555;
}

.navbar.active .nav-link:hover,
.navbar.active .nav-link:focus {
    color: #555;
    text-decoration: none;
}

.navbar.active .navbar-brand {
    color: #555;
}

/* Change navbar styling on small viewports */
@media (max-width: 991.98px) {
    .navbar.active {
        background: #0e0e0e;
    }

    .navbar .navbar-brand,
    .navbar .nav-link {
        color: #555;
    }
}