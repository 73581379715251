* {
  box-sizing: border-box;
}

.column__footer {
  float: left;
  padding: 10px;
}

.wrapper__footer {
  color: #ffffff;
  background: #000;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px;
  width: 100%;
  text-align: left;
  font-family: sans-serif;
  font-size: 16px;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

/* Left Column Starts Here */
.wrapper__footer .left {
  width: 35%;
  display: inline-block;
  vertical-align: top;
  padding: 20px;
  padding-right: 20px;
  /* border-right: solid #fff 1px; */
}

.wrapper__footer .about {
  line-height: 20px;
  color: #e2e2e2;
  font-size: 13px;
  margin: 0;
}

.wrapper__footer .right .icons {
  margin-top: 25px;
}

.wrapper__footer .right .icons a {
  display: inline-block;
  width: 35px;
  height: 35px;
  cursor: pointer;
  background-color: #33383b;
  border-radius: 2px;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  line-height: 35px;
  margin-right: 15px;
  margin-bottom: 5px;
}

/* Left Column Ends Here */

/* Middle Column Starts Here */
.middle {
  /* width: 30%; */
  padding: 20px;
  border-left: solid #fff 1px;
}

.middle i {
  background-color: #33383b;
  color: #ffffff;
  font-size: 25px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  margin: 10px 15px;
  vertical-align: middle;
}

.middle i.fa-envelope {
  font-size: 17px;
  line-height: 38px;
}

.middle p {
  display: inline-block;
  color: #ffffff;
  vertical-align: middle;
  font-weight: normal;

  margin: 0;
}

.middle p span {
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 2;
}

.middle p a {
  color: #fff;
  text-decoration: none;
}

/* Middle Column Ends Here */

/* Right Column Starts Here */
.right {
  /* width: 35%; */
  /* display: inline-block; */
  vertical-align: top;
  /* padding: 20px; */
  padding-left: 28px !important;
  border-left: solid #fff 1px;
}

.right h2 {
  color: #ffffff;
  font-size: 36px;
  font-weight: normal;
  margin: 0;
}

.right h2 span {
  color: #0099ff;
}

.right .menu {
  color: #ffffff;
  margin: 20px 0 12px;
  padding: 0;
}

.right .menu a {
  display: inline-block;
  line-height: 1.8;
  text-decoration: none;
  color: inherit;
}

.right .menu a:hover {
  color: #0099ff;
}

.right .name {
  /* color: #0099ff; */
  color: #fff;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}

/* Right Column Ends Here */

/* Clear floats after the columns */
.row__footer:after {
  content: "";
  display: table;
  clear: both;
}

/* Stack The Columns */
@media screen and (max-width: 768px) {
  .column__footer {
    width: 100%;
  }

  .wrapper__footer {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

#map {
  height: 400px;
}