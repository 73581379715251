.tech-free-holidays-bg {
    /* background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.7)), url('techfreeholidays.jpg') !important; */
    background-size: cover !important;
    background-repeat: no-repeat;
    background-position: bottom;
    -webkit-background-size: cover cover !important;
    background-color: #000;
    padding: 24px;
    min-height: 325px;
    /* height: 350px; */
    color: #e6e6e6;
}

.make-left {
    text-align: right;
}

@media screen and (max-width: 768px) {
    .make-left {
        text-align: left !important;
    }
}